import axios from 'axios'

export default function apiCall(store, typeOfCall, endpoint, body, callback, errHandler) {
	axios({
		method: `${typeOfCall}`,
		url: store.state.devUrl + `${endpoint}`,
		headers: {
			'content-type': 'application/json',
			authorization: `Bearer ${localStorage.token}`,
		},
		data: body,
	})
		.then(result => {
			callback(result)
		})
		.catch(err => {
			errHandler(err)
		})
}
