<template>
	<SetMeta :API="false" Title="Support" />
	<div class="wrap">
		<div class="container">
			<div class="mr-lg-5 ml-lg-5 mb-4"></div>
			<h3 class="mb-5">Support</h3>
			<form @submit.prevent="supportForm" class="mt-5 col-12 col-lg-10 mx-auto">
				<div class="row text-left">
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="name">Your Name:</label>
							<input type="text" name="name" id="name" placeholder="Your full name" v-model="support.name" />
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="emailaddress">Your Email:</label>
							<input
								type="email"
								name="emailaddress"
								id="emailaddress"
								placeholder="Your email address"
								v-model="support.email"
							/>
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="reason">Reason for Support:</label>
							<select name="reason" id="reason" v-model="support.reason">
								<option value="" disabled>Please select an option</option>
								<option value="help">System Help</option>
								<option value="paymnet">Payment</option>
								<option value="other">Other</option>
							</select>
						</div>
					</div>
					<div class="col-12" v-if="support.reason === 'other'">
						<div class="d-flex flex-column">
							<label for="subject">Reason For Support:</label>
							<input
								type="text"
								name="subject"
								id="subject"
								placeholder="Please enter your reason for contacting support"
								v-model="support.otherReason"
							/>
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="msg">Your Message:</label>
							<textarea name="msg" id="msg" rows="3" placeholder="Enter your support msg" v-model="support.msg"></textarea>
						</div>
					</div>
					<div class="col">
						<button class="btn" type="submit">Submit</button>
					</div>
					<div class="col-12">
						<div v-if="error" class="alert alert-danger" role="alert">
							<p class="mb-0">{{ error }}</p>
						</div>
						<div v-if="msg" class="alert alert-success" role="alert">
							<p class="mb-0">{{ msg }}</p>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import SetMeta from '@/components/_Meta/SetData'
	import { ref, reactive } from 'vue'
	import axios from 'axios'
	import { useStore } from 'vuex'

	export default {
		setup() {
			// Conroller
			const store = useStore()

			// Data
			const error = ref('')
			const msg = ref('')
			const templateTypes = ref(null)
			const support = reactive({
				name: '',
				email: '',
				reason: '',
				msg: '',
			})

			// Methods
			const supportForm = () => {
				error.value = ''
				if (support.name === '') {
					error.value = 'Please enter your full name'
					return false
				} else if (support.email === '') {
					error.value = 'Please enter your email address'
					return false
				} else if (support.reason === '') {
					error.value = 'Please select a reason for contacting support'
					return false
				} else if (support.msg === '') {
					error.value = 'Please enter your msg'
					return false
				}
				axios({
					method: 'post',
					url: store.state.devUrl + 'api/Support/SendSupportRequest',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: support,
				})
					.then(() => {
						msg.value = 'Will get back to you shortly'
					})
					.catch(err => {
						console.log(err)
					})
			}

			// Created
			axios({
				method: 'get',
				url: store.state.devUrl + 'api/Admin/FetchTemplateType',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
				.then(response => {
					templateTypes.value = response.data
				})
				.catch(err => {
					console.log(err)
				})

			return {
				SetMeta,
				support,
				error,
				supportForm,
				templateTypes,
				msg,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		.create {
			width: 200px;
			background: #30245d;
			color: #fff;
			position: absolute;
			right: 65px;
			bottom: -60px;
			z-index: 2;
			transition: linear all 0.3s;
		}
		.create:hover {
			transform: perspective(1px) scale(1.05);
		}
		input,
		select {
			outline: none;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			height: 40px;
			margin-bottom: 25px;
			padding: 5px 15px;
		}
		textarea {
			outline: none;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			margin-bottom: 25px;
			padding: 5px 15px;
			resize: none;
		}
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}

	@media only screen and (max-width: 768px) {
		#app button.btn.create {
			position: initial;
		}
		.innerWrap > .row {
			flex-direction: column-reverse;
		}
	}
</style>
