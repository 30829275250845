<template>
	<div>
		<div class="white-bg drop-shadox d-none d-lg-block">
			<ul class="sideMenu">
				<!-- Check Router to change value -->
				<li v-if="isUserAdmin">
					<a href="/admin" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'Admin' }">
						<i class="fas fa-user-shield"></i>
						<p>Admin</p>
					</a>
				</li>
				<li>
					<a href="/" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'MyDebts' }">
						<img src="../../../assets/logos/icon.svg" alt="" />
						<p>My Accounts</p>
					</a>
				</li>
				<li>
					<a href="/my-profile" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'MyInfo' }">
						<i class="fas fa-user"></i>
						<p>My Profile</p>
					</a>
				</li>
				<li>
					<a href="/subscription" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'Subscription' }">
						<i class="fas fa-receipt"></i>
						<p>Subscriptions</p>
					</a>
				</li>
				<li>
					<a href="/support" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'ContactPreferences' }">
						<i class="fas fa-headset"></i>
						<p>Support</p>
					</a>
				</li>
				<li>
					<a class="row m-0 cursor" @click="logOut">
						<i class="fas fa-sign-out-alt"></i>
						<p>Sign out</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()
		const router = useRouter()
		const currentRouteName = computed(() => useRoute().name)
		const isUserAdmin = computed(() => store.state.user.data.isUserAdmin)
		const logOut = () => {
			localStorage.token = ''
			router.go('/login')
		}
		return {
			useRoute,
			computed,
			currentRouteName,
			store,
			logOut,
			isUserAdmin,
		}
	},
}
</script>

<style lang="scss" scoped>
.white-bg {
	padding: 0px;
	overflow: hidden;
	box-shadow: 1px 0px 6px #5f5f5f2e;
}
</style>

<style lang="scss" scoped>
.active {
	background: #efefef;
}
.sideMenu {
	padding: 0;
	margin-bottom: 0px;
	a {
		color: $darkBlue;
		align-items: center;
		transition: all ease 0.3s;
	}
	a:hover {
		color: $darkBlue;
		opacity: 0.8;
		text-decoration: none;
	}
	p {
		margin-bottom: 0px;
	}
	.cursor:hover,
	a:hover {
		cursor: pointer;
	}
	li {
		list-style: none;
	}
	p,
	i {
		font-size: 18px;
		padding: 15px 0px;
		margin-right: 10px;
		margin-left: 30px;
	}
	img {
		width: 20px;
		height: 18px;
		max-width: 100%;
		margin-right: 10px;
		position: relative;
		margin-left: 30px;
	}
	.fa-user-shield {
	}
}
.sideMenu.active {
	background: #efe;
}
</style>
