<template>
	<SetMeta :API="false" Title="My Information" />
	<h3>Edit your profile</h3>
	<form @submit.prevent="submitInfo" class="mt-5 w-75 mx-auto" v-if="loaded">
		<div class="row text-left">
			<div class="col-12">
				<div class="d-flex flex-column">
					<label for="fullName">Full Name/Your Company Name</label>
					<input v-model="userData.fullName" type="text" placeholder="lorem " name="fullName" required />
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-column">
					<label for="firstName">Your Email</label>
					<input v-model="userData.email" type="text" placeholder="example@gmail.com" name="firstName" required />
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-column">
					<label for="contactNum">Your Phone Number</label>
					<input v-model="userData.phone" type="text" placeholder="Phone Number" name="phone" />
				</div>
			</div>
			<div class="col-12">
				<div class="d-flex flex-column">
					<label for="Address">Address</label>
					<input v-model="userData.address" type="text" placeholder="Full Address" name="Address" required />
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-column">
					<label for="firstName">Post Code</label>
					<input v-model="userData.country" type="text" placeholder="Post Code" name="firstName" required />
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<div class="d-flex flex-column">
					<label for="firstName">Country</label>
					<input v-model="userData.postcode" type="text" placeholder="Country" name="firstName" required />
				</div>
			</div>
			<div class="row text-left m-0">
				<div class="col-12">
					<p>Please enter the account details your debtor should use to make payment, they will make payment to you directly</p>
				</div>
				<div class="col-12">
					<div class="d-flex flex-column p-relative">
						<label for="name">Name of account holder
							<i class="fas fa-info-circle">
								<div class="information">
									<p>This is the account the debtor will pay you directly</p>
								</div>
							</i>
						</label>
						<input type="text" v-model="userData.accName" placeholder="Full Name" name="name" required style="" />
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="d-flex flex-column p-relative">
						<label for="firstName">Account Number
							<i class="fas fa-info-circle">
								<div class="information">
									<p>This is the account the debtor will pay you directly</p>
								</div>
							</i>
						</label>
						<input type="tel" min="6" max="6" placeholder="12345678" name="dateMoneyOwed" required v-model="userData.accNumber"/>
					</div>
				</div>
				<div class="col-12 col-lg-6 p-relative">
					<label>Bank sort code: XX-XX-XX
						<i class="fas fa-info-circle">
							<div class="information">
								<p>This is the account the debtor will pay you directly</p>
							</div>
						</i>
					</label>
					<div class="d-flex align-items-baseline">
						<input type="text" maxlength="2" size="2" placeholder="42" v-model="userData.sortCode1" required />
						-
						<input type="text" maxlength="2" size="2" placeholder="42" v-model="userData.sortCode2" required />
						-
						<input type="text" maxlength="2" size="2" placeholder="42" v-model="userData.sortCode3" required />
					</div>
				</div>
			</div>
			<div class="col">
				<button class="btn" type="submit">Update</button>
				<button class="btn mt-4 complete" type="button" @click="viewProfile()">View Stripe Profile</button>
			</div>
			<div v-if="errors.length" class="alert alert-danger" role="alert">
				<p v-for="x in errors" :key="x.id">{{ x }}</p>
			</div>
			<div v-if="success" class="alert alert-success" role="alert">
				<p>Your info have been successfully updated</p>
			</div>
		</div>
	</form>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'
import { ref, reactive, computed } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
	setup() {
		const errors = ref([])
		const loaded = ref(false)
		const success = ref(false)
		const store = useStore()
		const getUserId = computed(() => store.state.user.data.id)
		const userData = reactive({})

		axios({
			method: 'get',
			url: store.state.devUrl + 'api/Profile/Fetch',
			headers: {
				authorization: `Bearer ${localStorage.token}`,
			},
		})
		.then(result => {
			userData.id = result.data.id
			userData.fullName = result.data.fullName
			userData.email = result.data.email
			userData.phone = result.data.phone
			userData.address = result.data.address
			userData.postcode = result.data.postcode
			userData.country = result.data.country
			userData.accName = result.data.accountName
			userData.accNumber = result.data.accountNumber
			userData.sortCode = result.data.sortCode
			if (result.data.sortCode) {
				userData.sortCode1 = result.data.sortCode.substring(0, 2)
				userData.sortCode2 = result.data.sortCode.substring(3, 5)
				userData.sortCode3 = result.data.sortCode.substring(6, 9)
			}

			loaded.value = true
		})
		.catch(err => {
			console.log(err)
		})

		const viewProfile = () => {
			axios({
				method: 'get',
				url: store.state.devUrl + 'api/Subscription/LinkCustomerToPortal',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
			.then(response => {
				// console.log(response.data)
				window.location.href = response.data
			})
			.catch(err => {
				errors.value.push(err.message)
				console.log(err)
			})
		}

		const submitInfo = () => {
			userData.sortCode = userData.sortCode1 + '-' + userData.sortCode2 + '-' + userData.sortCode3

			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Profile/Modify',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					id: userData.id,
					fullName: userData.fullName,
					email: userData.email,
					phone: userData.phone,
					address: userData.address,
					country: userData.country,
					postcode: userData.postcode,
					accName: userData.accName,
					accNumber: userData.accNumber,
					sortcode: userData.sortCode,
				},
			})
			.then(() => {
				success.value = true
			})
			.catch(err => {
				errors.value.push(err.message)
				console.log(err)
			})
		}

		return {
			SetMeta,
			userData,
			loaded,
			submitInfo,
			getUserId,
			errors,
			success,
			viewProfile
		}
	},
}
</script>

<style lang="scss" scoped>
.white-bg {
	background: #fff;
	margin: 30px 0px;
}
.btn.complete {
	background: #30245d;
	border: #30245d;
	color: #fff;
}
input {
	outline: none;
	border-color: #d7d7d7;
	border-style: solid;
	border-radius: 5px;
	height: 40px;
	margin-bottom: 25px;
	padding: 5px 15px;
}
.p-relative{
	position: relative;
}
label i:hover .information{
	display: block;
}
.information{
	display: none;
	position: absolute;
	left: -30px;
	top: -28px;
	max-width: 500px;
	background-color: #d7d7d7;

	p{
		font-size: 14px;
		font-weight: 400;
		margin: 0;
		padding: 4px 5px;
		white-space: nowrap;
	}
}
</style>
