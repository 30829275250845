<template>
	<div class="bg">
		<div class="container">
			<div class="row">
				<div class="col-12 col-xl-3">
					<ProfileBox />
					<SideMenu />
				</div>
				<div class="col-12 col-xl-9">
					<div class="row">
						<div class="col-12">
							<div class="white-bg">
								<MyDebts v-if="currentRouteName === 'MyDebts'" />
								<CreateDebt v-if="currentRouteName === 'CreateDebt'" />
								<MyInformation v-if="currentRouteName === 'MyInfo'" />
								<Subscription v-if="currentRouteName === 'Subscription'" />
								<SubscriptionSuccess v-if="currentRouteName === 'SubscriptionSuccess'" />
								<CanceledSubscription v-if="currentRouteName === 'CanceledSubscription'" />
								<ContactPreferences v-if="currentRouteName === 'ContactPreferences'" />
							</div>
						</div>
					</div>

					<!-- <p>{{ currentRouteName }}</p> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue'
	import { useRoute } from 'vue-router'
	import ProfileBox from '../../components/dashboard/SideBar/ProfileBox'
	import SideMenu from '../../components/dashboard/SideBar/SideMenu'
	import MyDebts from '../../components/dashboard/MainLayout/MyDebts'
	import CreateDebt from '../../components/dashboard/MainLayout/CreateDebt'
	import MyInformation from '../../components/dashboard/MainLayout/MyInformation'
	import Subscription from '../../components/dashboard/MainLayout/Subscription'
	import SubscriptionSuccess from '../../components/dashboard/MainLayout/SubscriptionSuccess'
	import CanceledSubscription from '../../components/dashboard/MainLayout/CanceledSubscription'
	import ContactPreferences from '../../components/dashboard/MainLayout/ContactPreferences'
	import { useStore } from 'vuex'

	export default {
		setup() {
			const currentRouteName = computed(() => useRoute().name)
			const store = useStore()
			store.commit('showNav')

			return {
				ProfileBox,
				SideMenu,
				MyDebts,
				CreateDebt,
				MyInformation,
				useRoute,
				computed,
				currentRouteName,
				ContactPreferences,
				Subscription,
				SubscriptionSuccess,
				CanceledSubscription,
				useStore,
				store,
			}
		},
	}
</script>

<style lang="scss">
	.bg {
		background: #f2f2f2;
	}
	button.btn {
		width: 100%;
		border: 1px solid #30245d;
		margin-top: 10px;
		margin-bottom: 5px;
	}
	button.btn.active {
		width: 100%;
		border: 1px solid #30245d;
		background-color: #30245d;
		margin-top: 10px;
		margin-bottom: 5px;
		color: #fff;
		cursor: no-drop;
	}
</style>
