<template>
	<SetMeta :API="false" Title="My Debts" />
	<div class="closeDebtPopup" v-if="completeDebtPopup">
		<div class="inner">
			<p>Are you sure you want to close this debt?</p>
			<button class="btn w-auto pl-3 pr-3" @click="closeCompletePopup()">Keep debt open</button>
			<button class="btn w-auto pl-3 pr-3 ml-3 highlighted" @click="completeDebt()">Close debt</button>
		</div>
	</div>
	<div class="wrap">
		<div class="container" v-if="debt === 0 || showCreateADebt">
			<div class="mr-lg-5 ml-lg-5 mb-4"></div>
			<h3 class="mb-5">You have no debts, please create one below</h3>
			<button @click="createDebt" class="btn w-auto create mb-4 pl-3 pr-2 center">
				Create a debt <i class="ml-2 fas fa-plus"></i>
			</button>
		</div>
		<div class="innerWrap" v-else>
			<div class="row">
				<div class="col-12">
					<button @click="createDebt" class="btn w-auto create mb-4 pl-3 pr-2">
						Create a debt <i class="ml-2 fas fa-plus"></i>
					</button>
				</div>
				<div class="col-12">
					<h3 class="mb-2">My Accounts</h3>
				</div>
			</div>
			<InfoDashboard />
			<div class="wrap mr-lg-5 ml-lg-5 mb-4 mt-4">
				<div class="row">
					<div class="col-12 col-xl-3">Due Date &amp; Last Updated:</div>
					<div class="col-12 col-xl-2">Current Status</div>
					<div class="col-12 col-xl-2">Debtor </div>
					<div class="col-12 col-xl-1">Amount</div>
					<div class="col-12 col-xl-4">See Debt</div>
				</div>
				<div v-for="x in allDebts" :key="x.id" class="row align-items-center">
					<div class="col-12 col-xl-3">
						{{ x.dueDate.slice(0, 10) }}
						<p>Ongoing</p>
					</div>
					<div class="col-12 col-xl-2">{{ x.stage }}</div>
					<div class="col-12 col-xl-2">{{ x.name }}</div>
					<div class="col-12 col-xl-1">£{{ x.amount }}</div>
					<div class="col-12 col-xl-4">
						<button class="btn w-auto pl-3 pr-3 mr-2" @click="seeDebt(x.id)">See Debt</button>
						<button class="btn w-auto pl-3 pr-3" @click="openCompletePopup(x)" v-if="x.stage !== 'Completed'">Close Debt</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import SetMeta from '@/components/_Meta/SetData'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import apiCall from '@/modules/MakeApiCall.js'
import UploadModal from '@/components/debt/sharedComponents/UploadModal'
import InfoDashboard from './snippets/InfoDashboard'

export default {
	setup() {
		const store = useStore()
		const router = useRouter()
		const debt = ref(store.state.user.data.debtLive)
		const showCreateADebt = ref(false)
		const allDebts = ref({})
		const errors = ref([])
		const currentDebt = ref(0)
		const completeDebtPopup = ref(false)
		const hidden = computed(() => store.state.modal1)
		const fileIds = computed(() => store.state.fileIds)

		const createDebt = () => {
			router.push('/create-debt')
		}
		const openCompletePopup = (x) => {
			currentDebt.value = x.id
			completeDebtPopup.value = true
		}
		const closeCompletePopup = () => {
			completeDebtPopup.value = false
		}
		const completeDebt = () => {
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/CompleteDebt',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					id: currentDebt.value,
				},
			})
			.then(() => {
				location.reload();
			})
			.catch(err => {
				console.log('err', err)
			})
		}


		// * api functions:
		const fetchAllCallBack = result => {
			allDebts.value = result.data
		}
		const submitCallBack = () => {
			router.go(router.currentRoute)
		}
		const errHandler = err => {
			errors.value.push(err.message)
		}

		const seeDebt = id => {
			store.commit('debtId', id)
			router.push({ name: 'DebtProcess', params: { id: id } })
		}

		apiCall(store, 'get', 'api/Debt/fetchAll', undefined, fetchAllCallBack, errHandler)

		return {
			SetMeta,
			InfoDashboard,
			submitCallBack,
			debt,
			allDebts,
			UploadModal,
			hidden,
			store,
			fileIds,
			fetchAllCallBack,
			showCreateADebt,
			createDebt,
			seeDebt,
			router,
			errors,
			completeDebtPopup,
			openCompletePopup,
			closeCompletePopup,
			currentDebt,
			completeDebt
		}
	},
}
</script>

<style lang="scss" scoped>
.closeDebtPopup{
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	padding-top: 100px;
	background-color: rgba(0,0,0,0.5);
	z-index: 12;

	.inner{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 30%;
		height: fit-content;
		max-height: 20vh;
		margin: auto;
		background-color: #fff;
		border-radius: 20px;
		padding: 30px;
		text-align: center;
	}
}

.wrap {
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: -30px;
	.create {
		width: 200px;
		background: #30245d;
		color: #fff;
		position: absolute;
		right: 65px;
		bottom: -60px;
		z-index: 2;
		transition: linear all 0.3s;
	}
	.create:hover {
		transform: perspective(1px) scale(1.05);
	}
	.center {
		position: relative;
		top: 0;
		left: 0;
	}
	input {
		outline: none;
		border-color: #d7d7d7;
		border-style: solid;
		border-radius: 5px;
		height: 40px;
		margin-bottom: 25px;
		padding: 5px 15px;
	}
	> .row:nth-child(odd) {
		background: #efefef;
	}
	.row {
		padding: 15px 0px;
	}
}

@media only screen and (max-width: 768px) {
	.wrap {
		border-radius: 0px;
	}
	#app button.btn.create {
		position: initial;
	}
	.innerWrap > .row {
		flex-direction: column-reverse;
	}
}
</style>
