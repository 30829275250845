<template>
	<SetMeta :API="false" Title="Subscriptions" />
	<h3 class="mb-2 text-center">Choose Your Subscription</h3>
	<p class="mb-5 pl-5 pr-5">Select the Subscription that suits your needs depending on number of cases and requirements, you can change your subscription at any time</p>
	<div class="container pr-lg-5 pl-lg-5">
		<div class="row">
			<div class="col-12 col-lg-4 mb-4" v-for="x in subscriptions" :key="x.id">
				<div class="bg">
					<div class="top">
						<div class="d-flex flex-column">
							<div class="row">
								<img :src="x.imgSrc" width="34" height="33" alt="purple" title="purple" class="img-responsive wp-image-210" />
								<h4 class="title-heading-left" data-fontsize="30" data-lineheight="40.8px">
									{{ x.title }}
								</h4>
							</div>
						</div>
					</div>
					<div class="bottom">
						<ul class="mb-3 pt-2 pl-3 pr-2">
							<li v-for="i in x.points" :key="i.id">
								<div class="d-flex align-items-center"><i class="fusion-li-icon fa-check fas mr-2"></i> {{ i }}</div>
							</li>
						</ul>
						<button @click="handleClick(x)" class="btn mb-4 w-75 max-auto" v-if="x.ringColour !== store.state.user.data.ringColor">
							Buy Now:<span v-if="x.price > 0"><strong>£{{ x.price }}</strong></span>
						</button>
						<button class="btn mb-4 w-75 max-auto active" type="button" v-else>
							Current
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'
import { ref } from 'vue'
// import { loadStripe } from '@stripe/stripe-js'

import axios from 'axios'
import { useStore } from 'vuex'

export default {
	setup() {
		const subscriptions = ref([
			{
				imgSrc: 'https://www.debtshelf.co.uk/wp-content/uploads/2021/03/white.png',
				points: [
					'Full access to Debtshelf',
					'Access to Compliant Letter Templates',
					'Multiple Direct Collection Letters Sent by Email',
					'SMS Text Message Facility',
					'Full access to Expert Panel Members with DebtShelf user Discount',
					'No Find No Fee Trace Facility',
					'Upload Supporting Evidence'
				],
			},
			{
				imgSrc: 'https://www.debtshelf.co.uk/wp-content/uploads/2020/06/purple.png',
				points: [
					'Full Access to DebtShelf',
					'Access to Fully Compliant Letter Templates',
					'Multiple Direct Collection Letters Sent by Email',
					'SMS Text Reminder Facility',
					'Full Access to Expert Panel Members with DebtShelf user Discount',
					'No Find No Fee Trace Facility',
					'Upload Supporting Evidence'
				],
			},
			{
				imgSrc: 'https://www.debtshelf.co.uk/wp-content/uploads/2020/06/bronze.png',
				points: [
					'Upload Up To 5 Live Cases',
					'Full Access to DebtShelf',
					'Access to Fully Compliant Letter Templates',
					'Multiple Direct Collection Letters Sent by Email',
					'Full Access to Expert Panel Members with DebtShelf user Discount',
					'No Find No Fee Trace Facility',
					'Upload Supporting Evidence',
					'SMS Text Reminder Facility',
					'25% Discount from Expert Panel Members'
				],
			},
			{
				imgSrc: 'https://www.debtshelf.co.uk/wp-content/uploads/2020/06/silver.png',
				points: [
					'Upload Up To 10 Cases',
					'Full Access to DebtShelf',
					'Access to Fully Compliant Letter Templates',
					'Multiple Direct Collection Letters Sent by Email',
					'Full Access to Expert Panel Members with DebtShelf user Discount',
					'No Find No Fee Trace Facility',
					'Upload Supporting Evidence',
					'SMS Text Reminder Facility',
					'25% Discount from Expert Panel Members'
				],
			},
			{
				imgSrc: 'https://www.debtshelf.co.uk/wp-content/uploads/2020/06/gold-1.png',
				points: [
					'Upload Up To 20 Cases',
					'Full Access to DebtShelf',
					'Access to Fully Compliant Letter Templates',
					'Multiple Direct Collection Letters Sent by Email',
					'Full Access to Expert Panel Members with DebtShelf user Discount',
					'No Find No Fee Trace Facility',
					'Upload Supporting Evidence',
					'SMS Text Reminder Facility',
					'25% Discount from Expert Panel Members'
				],
			},
		])
		const store = useStore()

		const handleClick = async x => {
			axios({
				method: 'POST',
				url: store.state.devUrl + 'api/Subscription/UpdateUserSubscription',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: JSON.stringify({ productKey: x.productKey }),
			})
			.then(response => {
				console.log(response)
				location.reload()
			})
			.catch(err => {
				console.log('err', err)
			})
		}

		axios({
			method: 'GET',
			url: store.state.devUrl + 'api/Subscription/Fetch',
			headers: {
				'content-type': 'application/json',
				authorization: `Bearer ${localStorage.token}`,
			},
		})
		.then(result => {
			const response = result.data.slice().sort((a, b) => (a.id > b.id ? 1 : -1))
			var i
			for (i = 0; i < response.length; i++) {
				subscriptions.value[i].title = response[i].title
				subscriptions.value[i].productKey = response[i].productKey
				subscriptions.value[i].ringColour = response[i].ringColour
				subscriptions.value[i].price = response[i].price
			}
		})
		.catch(err => console.log('err', err))

		return {
			store,
			SetMeta,
			subscriptions,
			handleClick,
		}
	},
}
</script>

<style lang="scss" scoped>
img {
	width: 34px;
	height: 34px;
	margin: 10px;
}
.bg {
	background: #f2f2f2;
	border-top-right-radius: 50px;
	height: 100%;
	display: flex;
	flex-direction: column;
	ul {
		text-align: left;
		list-style: none;
		padding-left: 30px;
		i {
			position: relative;
			left: -5px;
		}
		li {
			margin: 15px 0px;
		}
	}
}
.top {
	color: #fff;
	background: transparent
	linear-gradient(
	180deg,
	#30255e 0%,
	#2f2862 10%,
	#2d2c67 20%,
	#2b316d 30%,
	#293774 40%,
	#273a78 45%,
	#263c7b 50%,
	#234383 60%,
	#20498b 70%,
	#1c5094 80%,
	#19579d 90%,
	#145fa7 100%
	)
	0% 0% no-repeat padding-box;
	border-top-right-radius: 50px;
	padding: 15px !important;
	text-align: center;
}
.bottom {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	align-items: center;
}

.title-heading-left {
	margin: auto 10px;
}
</style>
