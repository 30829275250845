<template>
	<div id="InfoDashboard" class="mr-2 mr-lg-5 ml-2 ml-lg-5 mb-4">
		<div class="row" v-if="loaded">
			<div class="col-xl-6 mb-3">
				<div class="box box-blue">
					<h3 style="padding-right: 50px;">Processing Cases</h3>
					<h5>Invoices Processing:</h5>
					<div class="circle">
						{{ data.processCases.caseCount }}
					</div>
					<div class="stats">
						<ul class="list-group">
							<li>
								Invoice/s Amount
								<span>£{{ data.processCases.totalDebt }}</span>
							</li>
							<li>
								Interest <span>£{{ data.processCases.totalInterest }}</span>
							</li>
						</ul>
					</div>
					<div class="total">
						<div class="d-flex justify-content-between">
							<div class="t_p">
								<div class="total-pending">Total pending on DebtShelf</div>
								<div class="total-amount">£{{ data.processCases.totalAmount }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-6 mb-3">
				<div class="box box-white">
					<h3 style="padding-right: 50px;">Resolved Cases</h3>
					<h5>Resolved Invoices:</h5>
					<div class="circle">
						{{ data.resolvedCases.caseCount }}
					</div>
					<div class="stats">
						<ul class="list-group">
							<li>
								Invoice/s Amount
								<span>£{{ data.resolvedCases.totalDebt }}</span>
							</li>
							<li>
								Interest <span>£{{ data.resolvedCases.totalInterest }}</span>
							</li>
						</ul>
					</div>
					<div class="total">
						<div class="total-pending">Total Collected on DebtShelf</div>
						<div class="total-amount">£{{ data.resolvedCases.totalAmount }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue'
	import axios from 'axios'
	import { useStore } from 'vuex'

	export default {
		setup() {
			let loaded = ref(false)
			const data = ref({})
			const store = useStore()
			axios({
				method: 'get',
				url: `${store.state.devUrl}api/Site/UserDashBoard`,
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
				.then(response => {
					data.value = response.data
					loaded.value = true
				})
				.catch(err => {
					console.log(err)
				})

			return { data, loaded }
		},
	}
</script>

<style lang="scss">
	#InfoDashboard {
		.box {
			display: flex;
			flex-direction: column;
			text-align: left;
			padding: 40px;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
			border-radius: 8px;
			height: 100%;
			justify-content: space-between;
			h3,
			h5 {
				padding: 10px 0px;
			}
			.stats {
				padding: 30px;
				padding-top: 10px;
				li {
					list-style: none;
					font-size: 18px;
					line-height: 40px;
					border-bottom: 2px solid #9493934a;
				}
			}
			.total-amount {
				font-size: 28px;
				font-weight: bold;
			}
		}
		.circle {
			width: 53px;
			height: 53px;
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.25);
			box-shadow: 0 8px 20px 0 #1f268730;
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			border-radius: 50%;
			border: 1px solid rgba(255, 255, 255, 0.18);
			display: flex;
			justify-content: center;
			align-items: center;
			color: #000;
			font-size: 22px;
			position: absolute;
			right: 40px;
			transition: all ease 0.2s;
		}
		.box-blue {
			color: #fff;
			background: linear-gradient(135deg, rgba(47, 38, 95, 1) 0%, rgba(20, 94, 166, 1) 100%);

			.circle {
				background: rgba(255, 255, 255, 0.3);
				border: none;
				color: white;
			}
		}
		.box-white {
			h5,
			.list-group {
				color: #676767;
			}
		}
	}

	@media only screen and (max-width: 600px) {
		#InfoDashboard {
			.box {
				padding: 15px;
				margin-bottom: 30px;
				h5 {
					padding-bottom: 0px;
				}
				.stats {
					padding: 0px;
					padding-bottom: 15px;
				}
				.circle {
					top: 20px;
					width: 40px;
					height: 40px;
					font-size: 18px;
				}
			}
		}
	}
</style>
