<template>
	<div class="wrap">
		<div class="container inner-wrap">
			<h3 class="mb-5">You have no debts, please create one below</h3>
			<div v-if="!show" class="row pl-3 pr-3">
				<div @click="nextStage(0)" class="col-12 col-md-4 mx-auto boxes mb-3">
					<h4>Create A <br />Commerical Debt <br /><i class="fas fa-briefcase mt-3"></i></h4>
				</div>
				<div @click="nextStage(1)" class="col-12 col-md-4 mx-auto boxes mb-3">
					<h4>Create A <br />Personal Debt<br /><i class="fas fa-people-arrows mt-3"></i></h4>
				</div>
			</div>
			<form v-if="show" @submit.prevent="submitEvent" class="mt-5 w-75 mx-auto">
				<div class="row text-left">
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="name">Name of the person/company that owes you money</label>
							<input v-model="createDebtBody.name" type="text" placeholder="Name " name="name" required />
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label for="firstName">When they were due to pay you</label>
							<datepicker
								v-model="createDebtBody.dueDate"
								placeholder="dd/mm/yyyy"
								inputFormat="dd/MM/yyyy"
								name="dateMoneyOwed"
								:upperLimit="new Date(d)"
								required
							/>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label for="contactNum">The amount they owe you</label>
							<input v-model="createDebtBody.amount" type="number" step="0.01" placeholder="950.50" name="contactNum" required />
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="addressLine1">Debtors Address</label>
							<input
								v-model="createDebtBody.addressLine1"
								type="text"
								placeholder="Street Number, Street Name..."
								name="Address"
								required
							/>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label for="postcode">Post Code</label>
							<input v-model="createDebtBody.postcode" type="text" placeholder="Post Code " name="postcode" required />
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label for="country">Country</label>
							<input v-model="createDebtBody.country" type="text" placeholder="Country" name="country" required />
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label class="pointer" for="debtEmail" @click="showToolTip1"
								>Debtors Email address
								<i class="fas fa-info-circle">
									<transition name="slide-fade"><span v-if="active1">We need this in order to send emails</span></transition>
								</i>
							</label>
							<input v-model="createDebtBody.debtEmail" type="email" placeholder="Email" name="debtEmail" required />
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="d-flex flex-column">
							<label for="smsReminder">Do you want to send SMS reminders?</label>
							<select name="smsReminder" id="smsReminder" v-model="createDebtBody.sms">
								<option value="" disabled>Please select an option</option>
								<option :value="true">Yes</option>
								<option :value="false">No</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-lg-6" v-if="createDebtBody.sms === true">
						<div class="d-flex flex-column">
							<label for="debtPhone" @click="showToolTip2" class="pointer"
								>Debtors Mobile Number
								<i class="fas fa-info-circle">
									<transition name="slide-fade">
										<span v-if="active2">We need this in order to send texts</span>
									</transition>
								</i>
							</label>
							<input v-model="createDebtBody.debtPhone" type="tel" placeholder="Phone Number" name="debtPhone" required />
						</div>
					</div>
				</div>
				<div class="row text-left">
					<div v-if="!uploadSuccess" class="col-xl-6 mx-auto">
						<button type="button" class="btn" @click="openModal">
							Click Here To Upload Invoice
						</button>
					</div>
					<div class="appendDashboard">
						<UploadModal
							dashboard=".appendDashboard"
							:open="hidden"
							:uppyId="'one'"
							:endpoint="`api/Debt/UploadDebtPreContent`"
							:autoRefresh="false"
							:isCreateADebt="true"
						/>
					</div>
					<div v-if="uploadSuccess" class="col-xl-6 mx-auto">
						<button class="btn" type="submit">
							Create Debt
							<img src="/images/icon.svg" class="ml-1" height="15" width="15" alt="" />
						</button>
					</div>
					<div v-if="errors.length" class="alert alert-danger" role="alert">
						<p v-for="x in errors" :key="x.id">{{ x }}</p>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import SetMeta from '@/components/_Meta/SetData'
	import { ref, reactive, computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import axios from 'axios'
	import UploadModal from '../../../components/debt/sharedComponents/UploadModal'
	// https://www.npmjs.com/package/vue3-datepicker
	import datepicker from 'vue3-datepicker'

	export default {
		components: {
			UploadModal,
		},
		setup() {
			const active1 = ref(false)
			const active2 = ref(false)
			const store = useStore()
			const router = useRouter()
			const debt = ref(store.state.user.data.debtLive)
			const allDebts = ref({})
			const errors = ref([])
			const hidden = computed(() => store.state.modal1)
			const uploadSuccess = computed(() => store.state.uploadSuccess)
			const show = ref(false)
			const fileIds = computed(() => store.state.fileIds)
			const isCommerical = ref(false)

			const d = new Date()

			const createDebtBody = reactive({
				name: '',
				dueDate: '',
				amount: '',
				addressLine1: '',
				country: '',
				postcode: '',
				sms: '',
				debtPhone: '',
				debtEmail: '',
				isCommercial: false,
				linkedFileId: [],
			})

			const showToolTip1 = () => (active1.value = !active1.value)
			const showToolTip2 = () => (active2.value = !active2.value)

			const nextStage = x => {
				// one line if statement
				if (x === 0) {
					createDebtBody.isCommercial = true
				}
				show.value = true
			}

			// * api functions:

			const openModal = () => {
				store.dispatch('toggleModal', 1)
			}
			const submitEvent = () => {
				createDebtBody.linkedFileId.push(fileIds.value.files[0])
				axios({
					method: 'post',
					url: store.state.devUrl + 'api/Debt/CreateDebt',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: createDebtBody,
				})
					.then(() => {
						location.reload()
					})
					.catch(err => {
						errors.value.push(err.message)
					})
			}
			const seeDebt = id => {
				store.commit('debtId', id)
				router.push({ name: 'DebtProcess', params: { id: id } })
			}
			return {
				SetMeta,
				debt,
				allDebts,
				nextStage,
				createDebtBody,
				openModal,
				hidden,
				show,
				active1,
				active2,
				showToolTip1,
				showToolTip2,
				isCommerical,
				uploadSuccess,
				store,
				fileIds,
				submitEvent,
				seeDebt,
				router,
				errors,
				datepicker,
				d,
			}
		},
	}
</script>

<style lang="scss">
	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		.btn {
			transition: all ease 0.3s;
		}
		.btn img {
			max-height: 15px;
		}
		.btn:hover img {
			filter: brightness(8.5);
		}
		.boxes {
			border: 1px solid;
			padding: 60px;
			border-radius: 30px;
			border-color: $darkBlue;
			transition: ease 0.3s all;
			&:hover {
				cursor: pointer;
				border-color: #fff;
				color: #fff;
				background: $darkBlue;
			}
		}
		.pointer:hover {
			cursor: pointer;
		}
		.create {
			width: 200px;
			background: #30245d;
			color: #fff;
			position: absolute;
			right: 65px;
			bottom: -60px;
			z-index: 2;
			transition: linear all 0.3s;
		}
		.create:hover {
			transform: perspective(1px) scale(1.05);
		}
		input,
		select {
			outline: none;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			width: 100%;
			height: 40px;
			margin-bottom: 25px;
			padding: 5px 15px;
		}
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}

	.v3dp__elements button:not(:disabled):hover span {
		background-color: $darkBlue !important;
		color: #fff !important;
	}

	.fas {
		position: relative;
		span {
			min-width: 185px;
			position: absolute;
			top: -52px;
			left: -25px;
			background: $darkBlue;
			font-weight: 400;
			border-radius: 8px;
			color: white;
			padding: 15px;
			font-size: 12px;
		}
	}
	.slide-fade-enter-active {
		transition: all 0.4s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(20px);
		opacity: 0;
	}

	@media only screen and (max-width: 768px) {
		#app button.btn.create {
			position: initial;
		}
		.innerWrap > .row {
			flex-direction: column-reverse;
		}

		.mt-5.w-75.mx-auto {
			width: 100% !important;
		}
	}
</style>
