<template>
	<div>
		<h1>Payment Successful</h1>
		<form id="manage-billing-form">
			<a href="/">
				<button type="button" class="btn w-50 mx-auto">
					Back To My Accounts
				</button>
			</a>
		</form>
		<pre></pre>
	</div>
</template>

<script>
const URL = window.location.href

import axios from 'axios'
import { ref } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const urlParams = new URLSearchParams(window.location.search)
		const sessionId = urlParams.get('session_id')
		const PriceId = ref(URL.split("?session_id=")[1])

		if (sessionId) {
			axios({
				method: 'POST',
				url: store.state.devUrl + 'api/Subscription/CompletePaymentSession',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: JSON.stringify({ sessionKey: PriceId.value }),
			}).catch(err => console.log('err', err))
		}
	},
}
</script>
